import styles from './price.module.css';

import classNames from 'classnames';

import { RUS_ID } from 'core/constants/currency';
import { Currency } from 'core/entities';

export function formatPrice(value: number, min = 4) {
  const ceilValue = Math.ceil(value);
  const price = ceilValue.toString();
  let result = <span>{ceilValue}</span>;

  if (price.length > min) {
    result = (
      <span>
        <span className={styles.value}>{formatPrice(parseInt(price.substr(0, price.length - 3), 10), 3)}</span>
        {price.substr(price.length - 3, 3)}
      </span>
    );
  }

  return result;
}

interface PriceProps {
  className?: string;
  currency: Currency;
  value: number;
  formatted?: boolean;
}

export const Price = ({ className, currency, value, formatted = true }: PriceProps) => {
  let currencyElement;
  if (currency.id === RUS_ID) {
    currencyElement = <span className={styles.currency}>₽</span>;
  } else {
    currencyElement = <span className={styles.currency}>{currency.entity}</span>;
  }

  let formattedValue = <span itemProp='priceRange'>{value}</span>;
  if (formatted) {
    formattedValue = formatPrice(value);
  }

  return (
    <span className={styles.price}>
      <span className={classNames(styles.value, className)}>{formattedValue}</span>
      {currencyElement}
    </span>
  );
};
