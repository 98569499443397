import { addDays, compareAsc, format, getYear, isSameDay, isSameMonth, startOfDay, startOfMonth } from 'date-fns';
import { ru } from 'date-fns/locale';

import { dateFormat } from 'core/constants/date-formats';

// в date-fns они с точками
export const SHORT_MONTH_NAMES = ['янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];
export const LONG_MONTH_NAMES = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря'
];

export function monthRange(from: Date, count: number): Date[] {
  const months = [];

  let month = new Date(from.getFullYear(), from.getMonth());

  let counter = 0;
  while (counter < count) {
    months.push(month);

    counter = counter + 1;
    month = new Date(from.getFullYear(), from.getMonth() + counter);
  }
  return months;
}

export function futureMonths(count = 12, includeStart = false): Date[] {
  const today = startOfDay(new Date());
  const months = monthRange(today, count);
  if (!includeStart) {
    months[0] = today;
  }
  return months;
}

export function weeksRange(start: Date, end?: Date): Date[][] {
  const finish = end || new Date(start.getFullYear(), start.getMonth() + 1);
  const result = [];
  let weekBuffer = [];
  let dayBuffer = start;
  while (dayBuffer < finish) {
    weekBuffer.push(dayBuffer);
    if (dayBuffer.getDay() === 0) {
      result.push(weekBuffer);
      weekBuffer = [];
    }
    dayBuffer = addDays(dayBuffer, 1);
  }
  if (weekBuffer.length) {
    result.push(weekBuffer);
  }
  return result;
}

export function pastWeeks(before: Date) {
  return weeksRange(startOfMonth(before), before);
}

export function formatDay(day: Date): string {
  const month = day.getMonth() + 1;
  const date = day.getDate();

  return [day.getFullYear(), month < 10 ? `0${month}` : month, date < 10 ? `0${date}` : date].join('-');
}

export function compareDays(first: Date, second: Date): number {
  if (isSameDay(first, second)) {
    return 0;
  }
  return compareAsc(first, second);
}

export function shortDateRangeFormat(start: Date, end: Date) {
  const startDay = start.getDate();
  const startMonth = SHORT_MONTH_NAMES[start.getMonth()];
  const endDay = new Date(end).getDate();
  const endMonth = SHORT_MONTH_NAMES[end.getMonth()];
  if (isSameMonth(start, end)) {
    return `${startDay}..${endDay}\xa0${endMonth}`;
  }
  return `${startDay}\xa0${startMonth}..${endDay}\xa0${endMonth}`;
}

export function longDateRangeFormat(start: Date, end: Date) {
  let startFormat = dateFormat.dayFullMonthYear;
  let endFormat = dateFormat.dayFullMonthYear;
  if (getYear(start) === getYear(end)) {
    startFormat = dateFormat.dayMonth;
    endFormat = dateFormat.dayFullMonthYear;
  }
  const startFns = format(start, startFormat, { locale: ru });
  const endFns = format(end, endFormat, { locale: ru });
  return `${startFns} — ${endFns}`;
}

export function getDifferenceInTime(start: Date, end: Date) {
  return start.getTime() - end.getTime();
}

export function getDifferenceInDays(start: Date, end: Date) {
  return getDifferenceInTime(start, end) / (1000 * 3600 * 24);
}

export function formatLogTime(logTime: string) {
  return format(new Date(logTime), dateFormat.log, { locale: ru });
}
