import styles from './checkbox.module.css';

import classNames from 'classnames';
import { ChangeEvent, ReactNode } from 'react';

import { NOOP } from 'core/utils/NOOP';

export type Variant = 'toggle' | 'switch' | 'tabs' | 'button';
export type Size = 'standard' | 'large';

export type CheckboxEvent = ChangeEvent<HTMLInputElement>;
export type OnChangeCheckbox = (() => void) | ((event: CheckboxEvent) => void);

interface CheckboxProps {
  children?: ReactNode | string;
  id: string;
  value?: string;
  checked?: boolean;
  onToggle?: OnChangeCheckbox;

  className?: string;
  size?: Size;
  labelClassName?: string;
  variant?: Variant;
  disabled?: boolean;
}

export const Checkbox = (props: CheckboxProps) => {
  const {
    children,
    id,
    value,
    checked = false,
    onToggle = NOOP,
    className,
    variant = 'toggle',
    size = 'standard',
    disabled = false
  } = props;

  const classes = classNames(styles.root, className, {
    [styles.toggle]: variant === 'toggle',
    [styles.switch]: variant === 'switch',
    [styles.tabs]: variant === 'tabs',
    [styles.button]: variant === 'button',
    [styles.disabled]: disabled
  });

  return (
    <div className={classes}>
      <input
        className={classNames({ [styles.checked]: checked })}
        type='checkbox'
        name={id}
        id={id}
        value={value}
        checked={checked}
        onChange={disabled ? NOOP : onToggle}
        disabled={disabled}
      />
      <label
        className={classNames({
          [styles.large]: size === 'large'
        })}
        htmlFor={id}
      >
        {children}
      </label>
    </div>
  );
};
