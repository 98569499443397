/*
Компонент отрендерит текстовый блок с переданным тегом "As".

"size" — определяет размер шрифта (mobile first)
"desktopSize" — переопределит размер шрифта для десктопа и планшета

каждому размеру шрифта соответствует свой размер line-height
*/

import styles from './text.module.css';

import classNames from 'classnames';
import { ReactNode } from 'react';

export enum TextColors {
  black = 'black',
  darkGray = 'darkGray',
  white = 'white',
  blue = 'blue',
  orange = 'orange',
  green = 'green',
  error = 'error'
}

type TSizes = 36 | 28 | 24 | 20 | 18 | 16 | 14 | 12 | 10 | 9;

interface TextProps {
  As?: 'span' | 'p' | 'div' | 'label' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  children?: ReactNode;
  color?: TextColors;
  bold?: boolean;
  size: TSizes;
  desktopSize?: TSizes;
  className?: string;
}

export function Text(props: TextProps) {
  const { As = 'span', children, color = TextColors.black, bold = false, size, desktopSize = size, className } = props;

  const classes = classNames(
    className,
    styles[`s${size}`],
    styles[color],
    { [styles.bold]: bold },
    { [styles[`d${desktopSize}`]]: desktopSize }
  );

  return <As className={classes}>{children}</As>;
}
