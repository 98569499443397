import styles from './actions.module.css';

import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';

import { HEADER_ACTIONS_TEXT } from 'core/constants/header';

import { CommonContext } from 'contexts/common';

import FavoriteIcon from 'components/common/icons/favorite.svg';
import HomeIcon from 'components/common/icons/home.svg';
import MessagesIcon from 'components/common/icons/messages.new.svg';
import MessagesWithMarkIcon from 'components/common/icons/messages.new.withmark.svg';
import UserIcon from 'components/common/icons/user.svg';
import { ActionItem } from 'components/layout/header/actions/action-item/action-item';

enum Pathes {
  bookmarks = '/starred/',
  messages = '/user/chat/',
  flats = '/user/flats/',
  profile = '/account/profile/',
  auth = '/account/auth/'
}

interface ActionsProps {
  isCentral?: boolean;
}

export const Actions = (props: ActionsProps) => {
  const { isCentral } = props;
  const { asPath } = useRouter();
  const { context } = useContext(CommonContext);
  const [isRender, setIsRender] = useState(false);

  let profileHref;

  if (!!context?.user && context.user.id !== 0) {
    profileHref = Pathes.profile;
  } else {
    profileHref = Pathes.auth;
  }

  useEffect(() => {
    setIsRender(true);
  }, []);

  return (
    <ul className={styles.root}>
      <ActionItem
        href={Pathes.bookmarks}
        text={HEADER_ACTIONS_TEXT.BOOKMARKS}
        icon={<FavoriteIcon />}
        isActive={isRender && asPath === Pathes.bookmarks}
        isFlatOwner={context?.user?.isFlatOwner}
        isCentral={isCentral}
        external={isCentral}
      />
      <ActionItem
        href={Pathes.flats}
        text={HEADER_ACTIONS_TEXT.FLATS}
        icon={<HomeIcon />}
        isActive={isRender && asPath === Pathes.flats}
        isFlatOwner={context?.user?.isFlatOwner}
        isHide={!context?.user?.isFlatOwner}
        external={isCentral}
        isCentral={isCentral}
      />
      <ActionItem
        href={Pathes.messages}
        text={HEADER_ACTIONS_TEXT.MESSAGES}
        icon={<MessagesIcon />}
        isActive={isRender && asPath.indexOf(Pathes.messages) !== -1}
        isFlatOwner={context?.user?.isFlatOwner}
        hasMark={!!context?.unread && context.unread > 0}
        external
        isCentral={isCentral}
        iconWithMark={<MessagesWithMarkIcon />}
      />
      <ActionItem
        href={profileHref}
        text={HEADER_ACTIONS_TEXT.PROFILE}
        icon={<UserIcon />}
        isActive={isRender && asPath === profileHref}
        isFlatOwner={context?.user?.isFlatOwner}
        external
        isCentral={isCentral}
      />
    </ul>
  );
};
