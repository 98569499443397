import styles from './header-back-link.module.css';

import ArrowIcon from 'components/common/icons/arrow.svg';
import CustomLink from 'components/common/link/link';

export interface HeaderBackLinkProps {
  href: string;
  text: string;
  external?: boolean;
}

export function HeaderBackLink({ href, text, external = true }: HeaderBackLinkProps) {
  return (
    <CustomLink className={styles.root} href={href} external={external}>
      <>
        <ArrowIcon />
        {text}
      </>
    </CustomLink>
  );
}
