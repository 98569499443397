import get from 'lodash/get';

import { DEFAULT_FILTERS } from 'core/constants/default-values';
import { DATE_REG } from 'core/constants/regex';
import { Currency } from 'core/entities';
import {
  BuildingTypesFilters,
  DatesFilterMacros,
  DatesFilters,
  FeaturesFilters,
  GuestsFilters,
  MapFilters,
  PricesFilters,
  RoomsExtraOptionsFilters,
  RoomsFilters
} from 'core/entities/filters';
import { BathroomType, BuildingType, KitchenType } from 'core/entities/housing';
import { SearchFilters, SearchPageParams } from 'core/entities/search';
import { getDifferenceInDays } from 'core/utils/calendar';
import { formatDateMacrosToISO } from 'core/utils/calendar/format-date-macros-to-iso';

export const getRoomsFromFilters = (rooms: RoomsFilters) => {
  return Object.values(rooms)
    .map((room, index) => {
      if (room) {
        if (index === 4) {
          return String(index + 1) + '+';
        }
        return String(index + 1);
      }
      return false;
    })
    .filter((item) => item)
    .join(',');
};

export const getRoomsFormApiFilters = (apiFilters: SearchFilters, query: SearchPageParams): RoomsFilters => {
  if (apiFilters.rooms) {
    const roomTypeMapping: { [key: string]: string } = {
      1: 'one',
      2: 'two',
      3: 'three',
      4: 'four',
      5: 'five',
      '5+': 'five',
      '4,5+': 'more_than_three',
      one: 'one',
      two: 'two',
      three: 'three',
      four: 'four',
      five: 'five',
      more_than_three: 'more_than_three'
    };
    const roomType = roomTypeMapping[apiFilters.rooms];
    const isMoreThanThree = roomType === 'more_than_three';

    return {
      one: roomType === 'one' || DEFAULT_FILTERS.rooms.one,
      two: roomType === 'two' || DEFAULT_FILTERS.rooms.two,
      three: roomType === 'three' || DEFAULT_FILTERS.rooms.three,
      four: roomType === 'four' || isMoreThanThree || DEFAULT_FILTERS.rooms.four,
      five: roomType === 'five' || isMoreThanThree || DEFAULT_FILTERS.rooms.five
    };
  }
  return getRoomsFromQuery(query);
};

export const getRoomsFromQuery = (query: SearchPageParams): RoomsFilters => {
  const value = decodeURIComponent(query.rooms || query.buildingType || '');
  const arr = value.split(',');
  return {
    one: Boolean(arr.find((item) => item === '1' || item === 'one')) || DEFAULT_FILTERS.rooms.one,
    two: Boolean(arr.find((item) => item === '2' || item === 'two')) || DEFAULT_FILTERS.rooms.two,
    three: Boolean(arr.find((item) => item === '3' || item === 'three')) || DEFAULT_FILTERS.rooms.three,
    four: Boolean(arr.find((item) => item === '4' || item === 'more_than_three')) || DEFAULT_FILTERS.rooms.four,
    five: Boolean(arr.find((item) => item === '5+' || item === 'more_than_three')) || DEFAULT_FILTERS.rooms.five
  };
};

export const getBuildingTypesFromFilters = (buildingTypes: BuildingTypesFilters) => {
  const arr = [];
  if (buildingTypes.flat) {
    arr.push(BuildingType.flat);
  }
  if (buildingTypes.cottage) {
    arr.push(BuildingType.cottage);
  }
  if (buildingTypes.room) {
    arr.push(BuildingType.room);
  }
  return arr.join(',');
};

export const getBuildingTypeFromApiFilters = (
  apiFilters: SearchFilters,
  query: SearchPageParams
): BuildingTypesFilters => {
  if (apiFilters.building_type) {
    return {
      flat:
        Boolean(apiFilters.building_type.find((item) => item === BuildingType.flat)) ||
        DEFAULT_FILTERS.buildingTypes.flat,
      room:
        Boolean(apiFilters.building_type.find((item) => item === BuildingType.room)) ||
        DEFAULT_FILTERS.buildingTypes.room,
      cottage:
        Boolean(apiFilters.building_type.find((item) => item === BuildingType.cottage)) ||
        DEFAULT_FILTERS.buildingTypes.cottage
    };
  }
  return getBuildingTypeFromQuery(query);
};

export const getBuildingTypeFromQuery = (query: SearchPageParams): BuildingTypesFilters => {
  const value = decodeURIComponent(query.buildingType || '');
  const arr = value.split(',');

  return {
    flat: Boolean(arr.find((item) => item === BuildingType.flat)) || DEFAULT_FILTERS.buildingTypes.flat,
    room: Boolean(arr.find((item) => item === BuildingType.room)) || DEFAULT_FILTERS.buildingTypes.room,
    cottage: Boolean(arr.find((item) => item === BuildingType.cottage)) || DEFAULT_FILTERS.buildingTypes.cottage
  };
};

export const getRoomsExtraOptionsFromQuery = (query: SearchPageParams): RoomsExtraOptionsFilters => {
  return {
    bathroom: Boolean(query.bathroomType),
    kitchen: Boolean(query.kitchenType)
  };
};

export const getRoomsExtraOptionsFromApiFilters = (
  apiFilters: SearchFilters,
  query: SearchPageParams
): RoomsExtraOptionsFilters => {
  const roomsExtraOptionsFromQuery = getRoomsExtraOptionsFromQuery(query);
  const privatedOrSharedKitchen =
    apiFilters.kitchenType === KitchenType.privated || apiFilters.kitchenType === KitchenType.shared;
  const privateBathroom = apiFilters.bathroomType === BathroomType.privated;

  if (privatedOrSharedKitchen) {
    roomsExtraOptionsFromQuery.kitchen = privatedOrSharedKitchen;
  }

  if (privateBathroom) {
    roomsExtraOptionsFromQuery.bathroom = privateBathroom;
  }

  return roomsExtraOptionsFromQuery;
};

export const getSleepingPlacesFromFilters = (guests: GuestsFilters, guestsSelected: boolean) => {
  const value = get(guests, 'adults', 1) + get(guests, 'children', 0) + get(guests, 'babies', 0);
  if (value >= 1 && guestsSelected) {
    return value;
  }
  return null;
};

export const getFeaturesFromQuery = (query: SearchPageParams): FeaturesFilters => ({
  ...DEFAULT_FILTERS.features,
  instantBooking: Boolean(query.instantBooking),
  reviewGood: Boolean(query.reviewGood),
  photocheck: Boolean(query.verified),
  studio: Boolean(query.studio),
  internet: Boolean(query.internet),
  reportDocs: Boolean(query.documents),
  parking: Boolean(query.parking),
  parties: Boolean(query.party),
  smoking: Boolean(query.smoking),
  animals: Boolean(query.pets),
  conditioner: Boolean(query.airconditioner),
  washingMachine: Boolean(query.washingMachine),
  fridge: Boolean(query.refrigerator),
  cooker: Boolean(query.cooker),
  teapot: Boolean(query.teapot),
  hairDryer: Boolean(query.hairDryer),
  iron: Boolean(query.iron),
  spa: Boolean(query.spa),
  sauna: Boolean(query.sauna),
  bbq: Boolean(query.bbq),
  pool: Boolean(query.pool),
  billiard: Boolean(query.billiard),
  karaoke: Boolean(query.karaoke),
  fireplace: Boolean(query.fireplace),
  babies: Boolean(query.babies),
  babyBed: Boolean(query.babyBed)
});

export const getFeaturesFromApiFilters = (apiFilters: SearchFilters, query: SearchPageParams): FeaturesFilters => {
  return {
    ...getFeaturesFromQuery(query),
    instantBooking: Boolean(apiFilters.instantBooking),
    photocheck: Boolean(apiFilters.verified),
    reviewGood: Boolean(apiFilters.reviewGood),
    studio: Boolean(apiFilters.studio),
    internet: Boolean(apiFilters.internet),
    reportDocs: Boolean(apiFilters.docs),
    parking: Boolean(apiFilters.parking),
    parties: Boolean(apiFilters.party),
    smoking: Boolean(apiFilters.smoke),
    animals: Boolean(apiFilters.animal),
    conditioner: Boolean(apiFilters.conditioner),
    washingMachine: Boolean(apiFilters.washingMachine),
    fridge: Boolean(apiFilters.refrigerator),
    cooker: Boolean(apiFilters.cooker),
    teapot: Boolean(apiFilters.teapot),
    hairDryer: Boolean(apiFilters.hairDryer),
    iron: Boolean(apiFilters.iron),
    spa: Boolean(apiFilters.spa),
    sauna: Boolean(apiFilters.sauna),
    bbq: Boolean(apiFilters.bbq),
    pool: Boolean(apiFilters.pool),
    billiard: Boolean(apiFilters.billiard),
    karaoke: Boolean(apiFilters.karaoke),
    fireplace: Boolean(apiFilters.fireplace),
    babies: Boolean(apiFilters.babies),
    babyBed: Boolean(apiFilters.babyBed)
  };
};

export const getPriceFromQuery = (query: SearchPageParams, currency: Currency): PricesFilters => {
  const checkPrices = !(Number(query.minPrice) > currency.maxPrice || Number(query.maxPrice) > currency.maxPrice);
  let min = currency.minPrice;
  let max = currency.maxPrice;
  if (checkPrices) {
    min = Number(query.minPrice) ? Number(query.minPrice) : currency.minPrice;
    max = Number(query.maxPrice) ? Number(query.maxPrice) : currency.maxPrice;
  }
  return {
    filtered: {
      min,
      max
    },
    range: {
      min: currency.minPrice,
      max: currency.maxPrice
    }
  };
};

export const getPriceFromApiFilters = (
  apiFilters: SearchFilters,
  query: SearchPageParams,
  currency: Currency
): PricesFilters => {
  let min = currency.minPrice;
  let max = currency.maxPrice;

  if (apiFilters.min_price) {
    min = apiFilters.min_price;
  } else if (query.minPrice) {
    min = query.minPrice;
  }
  if (apiFilters.price) {
    max = apiFilters.price;
  } else if (query.maxPrice) {
    max = query.maxPrice;
  }

  return {
    filtered: {
      min,
      max
    },
    range: {
      min: currency.minPrice,
      max: currency.maxPrice
    }
  };
};

export const getGuestsFromApiFilters = (apiFilters: SearchFilters, query: SearchPageParams): GuestsFilters => {
  const guests: GuestsFilters = {
    adults: DEFAULT_FILTERS.guests.adults,
    children: DEFAULT_FILTERS.guests.children,
    babies: DEFAULT_FILTERS.guests.babies
  };

  if (apiFilters.guests?.adults) {
    guests.adults = Number(apiFilters.guests.adults);
  } else if (query.adults) {
    guests.adults = Number(query.adults);
  }

  if (apiFilters.guests?.babies) {
    guests.babies = Number(apiFilters.guests.babies);
  } else if (query.baby) {
    guests.babies = Number(query.baby);
  }

  if (apiFilters.guests?.children) {
    guests.children = Number(apiFilters.guests.children);
  } else if (query.children) {
    guests.children = Number(query.children);
  }

  return guests;
};

export const getMapFromQuery = (query: SearchPageParams): MapFilters => {
  return {
    lng: query.lng ? query.lng : DEFAULT_FILTERS.map.lng,
    lat: query.lat ? query.lat : DEFAULT_FILTERS.map.lat,
    minLat: query.minLat ? query.minLat : DEFAULT_FILTERS.map.minLat,
    minLng: query.minLng ? query.minLng : DEFAULT_FILTERS.map.minLng,
    maxLat: query.maxLat ? query.maxLat : DEFAULT_FILTERS.map.maxLat,
    maxLng: query.maxLng ? query.maxLng : DEFAULT_FILTERS.map.maxLng,
    zoom: query.zoom ? query.zoom : DEFAULT_FILTERS.map.zoom
  };
};

export const getMapFromApiFilters = (apiFilters: SearchFilters, query: SearchPageParams): MapFilters => {
  const mapFilters = getMapFromQuery(query);

  if (apiFilters.point) {
    mapFilters.lat = apiFilters.point.lat;
    mapFilters.lng = apiFilters.point.lng;
  }

  if (apiFilters.area) {
    mapFilters.minLat = apiFilters.area.minLat;
    mapFilters.maxLat = apiFilters.area.maxLat;
    mapFilters.minLng = apiFilters.area.minLng;
    mapFilters.maxLng = apiFilters.area.maxLng;
  }

  if (apiFilters.boundaryKey) {
    mapFilters.boundaryKey = apiFilters.boundaryKey;
  }

  return mapFilters;
};

export const getDatesFromQuery = (query: SearchPageParams): DatesFilters => {
  const checkDates = Boolean(
    query.arrival && DATE_REG.exec(query.arrival) && query.departure && DATE_REG.exec(query.departure)
  );

  return {
    arrival: checkDates ? (query.arrival as string) : null,
    departure: checkDates ? (query.departure as string) : null,
    duration: checkDates
      ? getDifferenceInDays(new Date(query.departure as string), new Date(query.arrival as string))
      : null
  };
};

export const getDatesFromApiFilters = (apiFilters: SearchFilters, query: SearchPageParams): DatesFilters => {
  const datesFilters = getDatesFromQuery(query);

  let arrival = null;
  if (apiFilters.arrival) {
    if (DATE_REG.exec(apiFilters.arrival)) {
      arrival = apiFilters.arrival;
    } else {
      arrival = formatDateMacrosToISO(apiFilters.arrival as DatesFilterMacros);
    }
  }
  let departure = null;
  if (apiFilters.departure) {
    if (DATE_REG.exec(apiFilters.departure)) {
      departure = apiFilters.departure;
    } else {
      departure = formatDateMacrosToISO(apiFilters.departure as DatesFilterMacros);
    }
  }

  if (arrival) {
    datesFilters.arrival = arrival;
  }
  if (departure) {
    datesFilters.departure = departure;
  }

  if (datesFilters.arrival && datesFilters.departure) {
    datesFilters.duration = getDifferenceInDays(new Date(datesFilters.departure), new Date(datesFilters.arrival));
  }

  return datesFilters;
};

export const getSelectedGuestsFromQuery = (query: SearchPageParams) => {
  return Boolean(query.guestsSelected);
};
