const onServer = typeof window === 'undefined' || typeof document === 'undefined';

export function getClientHeight() {
  if (onServer) {
    return 0;
  }

  let height = window.innerHeight;

  if (!height) {
    height = document.documentElement.clientHeight;

    if (!height) {
      height = document.body.offsetHeight;
    }
  }

  return height;
}

export function getClientWidth() {
  if (onServer) {
    return 0;
  }

  let width = window.innerWidth;

  if (!width) {
    width = document.documentElement.clientWidth;

    if (!width) {
      width = document.body.offsetWidth;
    }
  }

  return width;
}

export function serializeQuery(query: object) {
  const parts: string[] = [];

  Object.entries(query).forEach(([key, value]) => {
    if (value) {
      const encodedKey = encodeURIComponent(key);

      if (Array.isArray(value)) {
        const encodedValue = value.map((item: string | number) => encodeURIComponent(item)).join(',');
        parts.push(`${encodedKey}=${encodedValue}`);
      } else if (value !== null && typeof value === 'object') {
        parts.push(serializeQuery(value as object));
      } else {
        const encodedValue: string | number | boolean = encodeURIComponent(value as string | number | boolean);

        parts.push(`${encodedKey}=${encodedValue}`);
      }
    }
  });

  return parts.filter((item) => item).join('&');
}
