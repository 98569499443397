import styles from './features.module.css';

import AnimalsIcon from './icons/animal.svg';
import BabiesIcon from './icons/babies.svg';
import BabyBedIcon from './icons/baby-bed.svg';
import BBQIcon from './icons/bbq.svg';
import BilliardIcon from './icons/billiard.svg';
import ConditionerIcon from './icons/conditioner.svg';
import CookerIcon from './icons/cooker.svg';
import DocsIcon from './icons/docs.svg';
import FireplaceIcon from './icons/fireplace.svg';
import FridgeIcon from './icons/fridge.svg';
import HairDryerIcon from './icons/hair-dryer.svg';
import InternetIcon from './icons/internet.svg';
import IronIcon from './icons/iron.svg';
import KaraokeIcon from './icons/karaoke.svg';
import LightningIcon from './icons/lightning.svg';
import ParkingIcon from './icons/parking.svg';
import PartiesIcon from './icons/party.svg';
import PhotocheckIcon from './icons/photocheck.svg';
import PoolIcon from './icons/pool.svg';
import ReviewGoodIcon from './icons/review-good.svg';
import SaunaIcon from './icons/sauna.svg';
import SmokeIcon from './icons/smoke.svg';
import SPAIcon from './icons/spa.svg';
import StudioIcon from './icons/studio.svg';
import TeapotIcon from './icons/teapot.svg';
import WashingMachineIcon from './icons/washing-machine.svg';

import classNames from 'classnames';

import { FeaturesFilters, FiltersFeaturesNames } from 'core/entities/filters';

import { Checkbox, OnChangeCheckbox, Variant as CheckboxVariant } from 'components/common/checkbox/checkbox';

type Variant = 'switches' | 'buttons';

interface FeaturesFilterProps {
  className?: string;
  variant?: Variant;
  onChange: OnChangeCheckbox;
  features: FeaturesFilters;
}

export const FeaturesFilter = (props: FeaturesFilterProps) => {
  const classes = classNames(styles.root, props.className, {
    [styles.buttons]: props.variant === 'buttons'
  });

  let checkboxVariant: CheckboxVariant = 'switch';
  if (props.variant === 'buttons') {
    checkboxVariant = 'button';
  }

  return (
    <div className={classes}>
      <div className={styles.section}>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='instantBooking'
            value='1'
            variant={checkboxVariant}
            checked={props.features.instantBooking}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <LightningIcon />
              </div>
              <div>{FiltersFeaturesNames.instantBooking}</div>
            </div>
          </Checkbox>
        </div>

        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='reviewGood'
            value='1'
            variant={checkboxVariant}
            checked={props.features.reviewGood}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <ReviewGoodIcon />
              </div>
              <div>{FiltersFeaturesNames.reviewGood}</div>
            </div>
          </Checkbox>
        </div>

        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='photocheck'
            value='1'
            variant={checkboxVariant}
            checked={props.features.photocheck}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <PhotocheckIcon />
              </div>
              <div>{FiltersFeaturesNames.photocheck}</div>
            </div>
          </Checkbox>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='studio'
            value='1'
            variant={checkboxVariant}
            checked={props.features.studio}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <StudioIcon />
              </div>
              <div>{FiltersFeaturesNames.studio}</div>
            </div>
          </Checkbox>
        </div>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='internet'
            value='1'
            variant={checkboxVariant}
            checked={props.features.internet}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <InternetIcon />
              </div>
              <div>{FiltersFeaturesNames.internet}</div>
            </div>
          </Checkbox>
        </div>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='reportDocs'
            value='1'
            variant={checkboxVariant}
            checked={props.features.reportDocs}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <DocsIcon />
              </div>
              <div>{FiltersFeaturesNames.reportDocs}</div>
            </div>
          </Checkbox>
        </div>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='parking'
            value='1'
            variant={checkboxVariant}
            checked={props.features.parking}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <ParkingIcon />
              </div>
              <div>{FiltersFeaturesNames.parking}</div>
            </div>
          </Checkbox>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='parties'
            value='1'
            variant={checkboxVariant}
            checked={props.features.parties}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <PartiesIcon />
              </div>
              <div>{FiltersFeaturesNames.parties}</div>
            </div>
          </Checkbox>
        </div>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='smoking'
            value='1'
            variant={checkboxVariant}
            checked={props.features.smoking}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <SmokeIcon />
              </div>
              <span>{FiltersFeaturesNames.smoking}</span>
            </div>
          </Checkbox>
        </div>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='animals'
            value='1'
            variant={checkboxVariant}
            checked={props.features.animals}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <AnimalsIcon />
              </div>
              <div>{FiltersFeaturesNames.animals}</div>
            </div>
          </Checkbox>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='conditioner'
            value='1'
            variant={checkboxVariant}
            checked={props.features.conditioner}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <ConditionerIcon />
              </div>
              <div>{FiltersFeaturesNames.conditioner}</div>
            </div>
          </Checkbox>
        </div>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='washingMachine'
            value='1'
            variant={checkboxVariant}
            checked={props.features.washingMachine}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <WashingMachineIcon />
              </div>
              <div>{FiltersFeaturesNames.washingMachine}</div>
            </div>
          </Checkbox>
        </div>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='fridge'
            value='1'
            variant={checkboxVariant}
            checked={props.features.fridge}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <FridgeIcon />
              </div>
              <div>{FiltersFeaturesNames.fridge}</div>
            </div>
          </Checkbox>
        </div>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='cooker'
            value='1'
            variant={checkboxVariant}
            checked={props.features.cooker}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <CookerIcon />
              </div>
              <div>{FiltersFeaturesNames.cooker}</div>
            </div>
          </Checkbox>
        </div>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='teapot'
            value='1'
            variant={checkboxVariant}
            checked={props.features.teapot}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <TeapotIcon />
              </div>
              <div>{FiltersFeaturesNames.teapot}</div>
            </div>
          </Checkbox>
        </div>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='hairDryer'
            value='1'
            variant={checkboxVariant}
            checked={props.features.hairDryer}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <HairDryerIcon />
              </div>
              <div>{FiltersFeaturesNames.hairDryer}</div>
            </div>
          </Checkbox>
        </div>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='iron'
            value='1'
            variant={checkboxVariant}
            checked={props.features.iron}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <IronIcon />
              </div>
              <div>{FiltersFeaturesNames.iron}</div>
            </div>
          </Checkbox>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='spa'
            value='1'
            variant={checkboxVariant}
            checked={props.features.spa}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <SPAIcon />
              </div>
              <div>{FiltersFeaturesNames.spa}</div>
            </div>
          </Checkbox>
        </div>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='sauna'
            value='1'
            variant={checkboxVariant}
            checked={props.features.sauna}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <SaunaIcon />
              </div>
              <div>{FiltersFeaturesNames.sauna}</div>
            </div>
          </Checkbox>
        </div>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='bbq'
            value='1'
            variant={checkboxVariant}
            checked={props.features.bbq}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <BBQIcon />
              </div>
              <div>{FiltersFeaturesNames.bbq}</div>
            </div>
          </Checkbox>
        </div>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='pool'
            value='1'
            variant={checkboxVariant}
            checked={props.features.pool}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <PoolIcon />
              </div>
              <div>{FiltersFeaturesNames.pool}</div>
            </div>
          </Checkbox>
        </div>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='billiard'
            value='1'
            variant={checkboxVariant}
            checked={props.features.billiard}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <BilliardIcon />
              </div>
              <div>{FiltersFeaturesNames.billiard}</div>
            </div>
          </Checkbox>
        </div>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='karaoke'
            value='1'
            variant={checkboxVariant}
            checked={props.features.karaoke}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <KaraokeIcon />
              </div>
              <div>{FiltersFeaturesNames.karaoke}</div>
            </div>
          </Checkbox>
        </div>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='fireplace'
            value='1'
            variant={checkboxVariant}
            checked={props.features.fireplace}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <FireplaceIcon />
              </div>
              <div>{FiltersFeaturesNames.fireplace}</div>
            </div>
          </Checkbox>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='babies'
            value='1'
            variant={checkboxVariant}
            checked={props.features.babies}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <BabiesIcon />
              </div>
              <div>{FiltersFeaturesNames.babies}</div>
            </div>
          </Checkbox>
        </div>
        <div className={styles.addon}>
          <Checkbox
            className={styles.checkbox}
            id='babyBed'
            value='1'
            variant={checkboxVariant}
            checked={props.features.babyBed}
            onToggle={props.onChange}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <BabyBedIcon />
              </div>
              <div>{FiltersFeaturesNames.babyBed}</div>
            </div>
          </Checkbox>
        </div>
      </div>
    </div>
  );
};
