export const WORD_FORM_DAY = {
  ONE: 'день',
  FEW: 'дня',
  MANY: 'дней'
};

export const WORD_FORM_TWENTY_FOUR_HOURS = {
  ONE: 'сутки',
  FEW: 'суток',
  MANY: 'суток'
};

export const WORD_FORM_BONUS_RUBLE = {
  ONE: 'бонусный рубль',
  FEW: 'бонусных рубля',
  MANY: 'бонусных рублей'
};

export const WORD_FORM_FLAT = {
  ONE: 'объект',
  FEW: 'объекта',
  MANY: 'объектов'
};

export const WORD_FORM_FROM_FLAT = {
  ONE: 'объекта',
  FEW: 'объектов',
  MANY: 'объектов'
};

export const WORD_FORM_ADVERTISEMENT = {
  ONE: 'объявление',
  FEW: 'объявления',
  MANY: 'объявлений'
};

export const WORD_FORM_GUEST = {
  ONE: 'гость',
  FEW: 'гостей',
  MANY: 'гостей'
};

export const WORD_FORM_ADULT = {
  ONE: 'взрослый',
  FEW: 'взрослых',
  MANY: 'взрослых'
};

export const WORD_FORM_CHILDREN = {
  ONE: 'ребенок',
  FEW: 'детей',
  MANY: 'детей'
};

export const WORD_FORM_CHILDREN_FULL = {
  ONE: 'ребенок 2—12 лет',
  FEW: 'детей 2—12 лет',
  MANY: 'детей 2—12 лет'
};

export const WORD_FORM_BABIES = {
  ONE: 'младенец',
  FEW: 'младенцев',
  MANY: 'младенцев'
};

export const WORD_FORM_BABIES_FULL = {
  ONE: 'малыш до 2 лет',
  FEW: 'малышей до 2 лет',
  MANY: 'малышей до 2 лет'
};

export const WORD_FORM_FULL_DAYS = {
  ONE: 'сутки',
  FEW: 'суток',
  MANY: 'суток'
};

export const WORD_FORM_VIEW = {
  ONE: 'показ',
  FEW: 'показа',
  MANY: 'показов',
  ZERO: 'показов'
};

export const WORD_FORM_VISIT = {
  ONE: 'переход',
  FEW: 'перехода',
  MANY: 'переходов'
};

export const WORD_FORM_REVIEW = {
  ONE: 'отзыв',
  FEW: 'отзыва',
  MANY: 'отзывов'
};

export const WORD_FORM_TEXT_FOUND = {
  ONE: 'Найден',
  FEW: 'Найдено',
  MANY: 'Найдено'
};

export const WORD_FORM_TEXT_REQUEST = {
  ONE: 'запрос',
  FEW: 'запроса',
  MANY: 'запросов'
};

export const WORD_FORM_TEXT_REQUEST_SENT = {
  ONE: 'запрос отправлен',
  FEW: 'запроса отправлено',
  MANY: 'запросов отправлено'
};
