import styles from './layout.module.css';

import classNames from 'classnames';
import { MutableRefObject, ReactChild } from 'react';
import * as React from 'react';

import { SearchFilters, SearchOptions, SearchPageParams } from 'core/entities/search';
import { CommonPageContext } from 'core/services/context/context';
import { MarkVisitedService } from 'core/services/mark_visited';

import { AppVirtualLinkProvider } from 'contexts/app-virtual-link';
import { FilterControlsProvider } from 'contexts/filters/filter-control';
import { FiltersProvider } from 'contexts/filters/filters';
import { getSearchBarInitialState, SearchBarProvider } from 'contexts/search/search-bar';

import { usePageLoader } from 'hooks/page-loader';

import { FiltersHeader } from 'components/layout/filters-header/filters-header';
import { Footer } from 'components/layout/footer/footer';
import { Header } from 'components/layout/header/header';
import { HeaderBackLinkProps } from 'components/layout/header-back-link/header-back-link';
import { HeaderContent } from 'components/layout/header-content';
import { PageLoader } from 'components/layout/page-loader/page-loader';

interface LayoutProps {
  children?: ReactChild;
  mainHost: string;
  context: CommonPageContext;

  backLink?: Optional<HeaderBackLinkProps>;
  hasSearchBar?: boolean;
  searchOptions?: SearchOptions;

  slimFooter?: boolean;
  noHeader?: boolean;
  noFooter?: boolean;
  onlyDesktopFooter?: boolean;
  isCentral?: boolean;
  isTransparentHead?: boolean;
  hasMobileSearchBar?: boolean;
  withoutLoading?: boolean;

  headerRef?: MutableRefObject<Optional<HTMLDivElement>>;
}

// eslint-disable-next-line complexity
export const Layout = (props: LayoutProps) => {
  const {
    slimFooter = false,
    noHeader = false,
    noFooter = false,
    isCentral,
    isTransparentHead,
    hasMobileSearchBar
  } = props;
  const hasSearchFilters = Boolean(props.searchOptions);
  const pageIsLoading = usePageLoader();

  let className = styles.root;
  const isApp = props.context.isApp;
  const isImpersonated = Boolean(props.context.user?.isImpersonated);

  if (isApp) {
    className = classNames(className, styles.app);
  }
  if (isCentral) {
    className = classNames(className, styles.central);
  }
  let footerClassName = styles.footer;
  if (props.onlyDesktopFooter || isApp) {
    footerClassName = classNames(styles.footer, styles.footer_only_desktop);
  }

  const renderChildren = () => {
    if (props.withoutLoading) {
      return props.children;
    }
    return pageIsLoading ? <PageLoader /> : props.children;
  };

  React.useEffect(() => {
    // Защита от атак, см код бекенда на эту апишку
    const service = new MarkVisitedService(props.mainHost);
    service
      .setMark()
      .then(() => {})
      .catch(() => {});
  }, []);

  return (
    <FiltersProvider
      searchFilters={hasSearchFilters ? (props.searchOptions?.searchFilters as SearchPageParams) : {}}
      apiFilters={hasSearchFilters ? (props.searchOptions?.apiFilters as SearchFilters) : {}}
      currency={props.context.currencies.current}
      isLanding={props.searchOptions?.isLanding}
    >
      <FilterControlsProvider>
        <SearchBarProvider
          initial={getSearchBarInitialState(
            props.context,
            props.searchOptions?.meta,
            props.searchOptions?.city,
            props.searchOptions?.searchFilters,
            props.context.currencies.current
          )}
        >
          <AppVirtualLinkProvider>
            <div className={className}>
              {hasSearchFilters ? (
                <FiltersHeader className={styles.header} mainHost={props.mainHost} city={props.searchOptions?.city} />
              ) : (
                <Header
                  className={styles.header}
                  noHeader={noHeader}
                  isCentral={isCentral}
                  isTransparentHead={isTransparentHead}
                  isApp={isApp}
                  hasMobileSearchBar={hasMobileSearchBar}
                  headerRef={props.headerRef}
                >
                  <HeaderContent
                    backLink={props.backLink}
                    mainHost={props.mainHost}
                    hasSearchBar={props.hasSearchBar}
                    hasMobileSearchBar={hasMobileSearchBar}
                  />
                </Header>
              )}
              <main
                className={classNames(styles.content, {
                  [styles.withFilters]: hasSearchFilters,
                  [styles.central]: isCentral
                })}
              >
                {renderChildren()}
              </main>
              <Footer
                className={footerClassName}
                footerContext={props.context.footer}
                user={{
                  isImpersonated,
                  id: props.context.user?.id || 0
                }}
                isCentral={isCentral}
                noFooter={noFooter}
                slim={slimFooter}
                mainHost={props.mainHost}
                chaport={props.context.chaport}
              />
            </div>
          </AppVirtualLinkProvider>
        </SearchBarProvider>
      </FilterControlsProvider>
    </FiltersProvider>
  );
};
